<template>
  <el-card class="box-card profile-card">
    <el-button
      style="float: right; padding: 3px 0"
      type="text"
      @click="edit_invoice"
    >
      編輯
    </el-button>
    <!-- 發票資料 -->
    <el-descriptions
      v-if="$store.getters.getUserAccount.carrier_type >= 0"
      title="發票設定"
      :colon="false"
      :column="1"
      label-class-name="formLabel"
      content-class-name="formContent"
    >
      <el-descriptions-item label="發票格式">
        {{ invoice_name }}
      </el-descriptions-item>
      <el-descriptions-item
        v-for="label in invoice_labels"
        :label="label.text"
        :key="label.text"
      >
        {{ label.prop }}
      </el-descriptions-item>
    </el-descriptions>

    <!-- 空白畫面 -->
    <el-descriptions v-else title="發票設定" :colon="false">
      <el-descriptions-item
        label="尚未設定發票，請點擊編輯進行設定"
      ></el-descriptions-item>
    </el-descriptions>
  </el-card>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'get-invoice',
  props: ['action'],
  computed: {
    ...mapGetters(['getUserAccount']),
    invoiceData() {
      return {
        type0: {
          typeName: '三聯式電子發票(公司)',
          labels: [
            {
              text: '公司抬頭',
              prop: this.getUserAccount.invoice_heading,
            },
            {
              text: '統一編號',
              prop: this.getUserAccount.carrier_number,
            },
          ],
        },
        type1: {
          typeName: '二聯式電子發票(個人)',
          labels: [
            {
              text: '儲存形式',
              prop: `手機條碼載具 ${this.getUserAccount.carrier_number}`,
            },
          ],
        },
        type2: {
          typeName: '二聯式電子發票(個人)',
          labels: [
            {
              text: '儲存形式',
              prop: `自然人憑證載具 ${this.getUserAccount.carrier_number}`,
            },
          ],
        },
        type3: {
          typeName: '捐贈',
          labels: [
            {
              text: '捐贈碼',
              prop:
                this.getUserAccount.carrier_number +
                ' ' +
                this.getUserAccount.social_welfare_name,
            },
          ],
        },
      };
    },
    invoice_name() {
      let index = this.getUserAccount.carrier_type;
      return index >= 0 ? this.invoiceData[`type${index}`].typeName : '';
    },
    invoice_labels() {
      const index = this.getUserAccount.carrier_type;
      return index >= 0 ? this.invoiceData[`type${index}`].labels : [];
    },
  },
  data() {
    return {};
  },

  methods: {
    edit_invoice() {
      this.$emit('update:action', 'edit');
    },
  },
};
</script>
