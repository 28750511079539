<template>
  <div id="user-profile">
    <user-point v-if="is_setData"></user-point>
    <user-data v-if="is_setData"></user-data>
    <user-invoice v-if="is_setData"></user-invoice>
  </div>
</template>
<script>
import userPoint from '@/components/account/userPoint';
import userData from '@/components/account/userData';
import userInvoice from '@/components/account/userInvoice';
import { mapActions } from 'vuex';
export default {
  name: 'user-profile',
  components: {
    userPoint,
    userData,
    userInvoice,
  },
  data() {
    return {
      is_setData: false,
    };
  },
  methods: {
    ...mapActions(['doGetData', 'doGetPoint', 'doLogout']),
    async getData() {
      const user_id = sessionStorage.getItem('user_id');
      await this.doGetData(user_id);
      await this.doGetPoint();
      this.is_setData = true;
    },
  },
  created() {
    this.getData();
  },
};
</script>
<style lang="scss" scoped>
/deep/.profile-card {
  margin-bottom: 15px;
  border-radius: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04) !important;
  font-family: PingFangTC-Regular;
}

/deep/.profile-card .formLabel {
  color: #909399;
  width: 70px;
  text-align: right;
  padding-right: 20px;
}
/deep/.profile-card .formContent {
  color: #000;
}
</style>
