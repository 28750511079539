<template>
  <div id="user-invoice">
    <get-invoice v-show="action === 'get'" :action.sync="action"></get-invoice>
    <edit-invoice
      v-show="action === 'edit'"
      :action.sync="action"
    ></edit-invoice>
  </div>
</template>
<script>
import EditInvoice from './editInvoice.vue';
import GetInvoice from './getInvoice.vue';
export default {
  name: 'user-invoice',
  components: {
    GetInvoice,
    EditInvoice,
  },
  data() {
    return {
      action: 'get',
    };
  },
};
</script>
