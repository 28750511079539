<template>
  <div id="user-point">
    <!-- 剩餘點數 -->
    <el-card class="box-card profile-card">
      <div>
        <el-button
          style="float: right; padding: 3px 0"
          type="text"
          @click="dialogTargetQVisible = true"
        >
          <i class="el-icon-warning"></i>
          Target-Q點數使用期限
        </el-button>
        <div style="display: flex">
          <div class="general">
            <span style="font-weight: bold">一般點數</span>
            <div style="font-size: 26px; font-weight: bold; margin-top: 12px">
              {{ getUserPointData.point || 0 }}
            </div>
          </div>
          <div class="limited" style="margin-left: 30px">
            <span style="font-weight: bold">Target-Q點數</span>
            <div style="font-size: 26px; font-weight: bold; margin-top: 12px">
              {{ getUserPointData.targetQ_point || 0 }}
            </div>
          </div>
        </div>
        <el-button
          style="margin-top: 20px"
          @click="$router.push('/trade-detail')"
        >
          查詢交易明細
        </el-button>
      </div>
    </el-card>
    <!-- 儲值點數 -->
    <!-- <el-card class="box-card profile-card">
      <div>
        <span style="font-weight: bold">儲值點數</span>
        <el-button
          style="float: right; padding: 3px 0"
          type="text"
          @click="dialogDiscountVisible = true"
        >
          <i class="el-icon-warning"></i>
          優惠方案
        </el-button>
      </div>
      <div class="TQ-info" style="margin-top: 8px">
        <p>中國信託 城中分行</p>
        <p style="color: #000; font-weight: bold; font-size: 18px">
          (822) 107540483352
        </p>
        <p style="font-size: 10px">
          *請於匯款成功後來信
          <span style="font-weight: 700">support@qmartech.com</span>
          告知
          <span style="font-weight: 700">
            會員帳號(email)、申請人姓名、聯絡電話、戶名、帳號後五碼。
          </span>
        </p>
        <p style="font-size: 10px">*手續費請自行吸收。</p>
      </div>
    </el-card> -->
    <!-- 彈跳視窗 -->
    <el-dialog
      title="帳號優惠方案"
      :visible.sync="dialogDiscountVisible"
      width="410px"
      style="font-family: PingFangTC-Regular"
    >
      <div class="small-text">
        <p>*儲值點數無期限且不得轉贈。</p>
        <p>*Target-Q點數不得退款且隔年12/31到期。</p>
        <p>*單一帳號不得跨方案使用，如欲跨方案，請再申請新帳號使用。</p>
        <p>*企業/學術或公益團體方案開立發票需使用隸屬單位統編</p>
      </div>
      <el-table :data="discountData" :cell-class-name="tableCellClassName">
        <el-table-column property="add" label="儲值點數" width="110">
          <template slot-scope="scope">
            <i class="el-icon-star-off orange-icon"></i>
            <span style="margin-left: 3px">{{ scope.row.add }}</span>
          </template>
        </el-table-column>
        <el-table-column property="give" label="Target-Q點數" width="150">
          <template slot-scope="scope">
            <span v-show="scope.row.give">加贈</span>
            <i v-show="scope.row.give" class="el-icon-star-off blue-icon"></i>
            <span>{{ scope.row.give }}</span>
          </template>
        </el-table-column>
        <el-table-column property="price" label="價格"></el-table-column>
        <!-- <el-table-column
          property="discount"
          label=""
          width="70"
        ></el-table-column> -->
      </el-table>
    </el-dialog>

    <el-dialog
      title="Target-Q點數使用期限"
      :visible.sync="dialogTargetQVisible"
      width="410px"
      style="font-family: PingFangTC-Regular"
    >
      <div class="small-text">
        <p>*Target-Q點數於贈送當日起，使用期至隔年12月底。</p>
      </div>
      <el-table :data="getUserPointData.targetQ_point_expired_info">
        <el-table-column
          property="targetQ_point"
          label="Target-Q點數"
          width="200"
        ></el-table-column>
        <el-table-column
          property="expired_date"
          label="到期日"
        ></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';

//贈點與折扣
// let givePoint = {
//   type1: [
//     [250, 95],
//     [1000, 9],
//     [6000, 8],
//     [12500, 75],
//   ],
//   type2: [
//     [500, 9],
//     [1500, 85],
//     [7500, 75],
//     [15000, 7],
//   ],
//   type3: [
//     [1000, 8],
//     [2500, 75],
//     [10500, 65],
//     [20000, 6],
//   ],
// };

export default {
  name: 'user-point',
  data() {
    return {
      dialogDiscountVisible: false,
      dialogTargetQVisible: false,
    };
  },
  computed: {
    ...mapGetters(['getUserAccount', 'getUserPointData']),
    // typeTitle() {
    //   let dialogTitle = ['個人', '企業', '學術/公益'];
    //   let index = this.getUserAccount.type_code * 1 - 1;
    //   return dialogTitle[index];
    // },
    discountData() {
      return [
        {
          add: '2000',
          price: 'NT 2,000',
        },
        {
          add: '3000',
          price: 'NT 3,000',
        },
        {
          add: '5000',
          give: '500',
          price: 'NT 5,000',
        },
        {
          add: '10000',
          give: '1500',
          price: 'NT 10,000',
        },
        {
          add: '25000',
          give: '5000',
          price: 'NT 25,000',
        },
        {
          add: '50000',
          give: '12500',
          price: 'NT 50,000',
        },
      ];
    },
  },
  methods: {
    ...mapMutations(['CHANGE']),
    tableCellClassName({ columnIndex }) {
      if (columnIndex === 3) {
        return 'doscount-col';
      }
      if (columnIndex === 1) {
        return 'give-col';
      }
      return 'fix-border';
    },
  },
};
</script>

<style lang="scss" scoped>
#user-point {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.TQ-info p {
  margin: 2px 0;
  color: #909399;
}
.box-card {
  width: 49%;
  min-width: 350px;
}

.small-text {
  font-size: 11px;
  color: #909399;
  margin: -30px 0 15px;
  border-top: 1px solid #ccc;
  padding-top: 10px;
}

.small-text p {
  margin: 3px 0;
}

/deep/ .el-table .fix-border {
  border-bottom: 1px dashed #ebeef5;
}

/deep/ .el-table .doscount-col {
  font-size: 17px;
  font-weight: 700;
  text-align: right;
  border-bottom: 1px dashed #ebeef5;
  padding-right: 10px;
}

/deep/ .el-table .give-col {
  font-weight: 500;
}

.orange-icon {
  border: 1px solid orange;
  border-radius: 50%;
  color: orange;
  font-size: 10px;
  font-weight: 700;
}

.blue-icon {
  border: 1px solid rgb(73, 182, 255);
  border-radius: 50%;
  color: rgb(73, 182, 255);
  font-size: 10px;
  font-weight: 700;
  margin: 0 3px;
}
</style>
