<template>
  <div id="user-data">
    <!-- 基本資料 -->
    <el-card id="basic-profile" class="box-card profile-card">
      <el-descriptions
        title="基本資料"
        :colon="false"
        :column="2"
        label-class-name="formLabel"
        content-class-name="formContent"
      >
        <el-descriptions-item label="姓名">
          {{ $store.getters.getUserAccount.name }}
        </el-descriptions-item>
        <el-descriptions-item label="帳號分類">
          {{ user_type_name }}帳號
        </el-descriptions-item>
        <el-descriptions-item label="職業別">
          {{ $store.getters.getUserAccount.occupation }}
        </el-descriptions-item>
        <el-descriptions-item label="信箱(帳號)">
          {{ $store.getters.getUserAccount.account }}
        </el-descriptions-item>
        <el-descriptions-item label="電話">
          {{ $store.getters.getUserAccount.phone }}
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
    <!-- 詳細資料 -->
    <el-card
      id="detail-profile"
      class="box-card profile-card"
      v-show="user_type_name !== '個人'"
    >
      <el-descriptions
        :title="`${detail_title}資訊`"
        :colon="false"
        :column="2"
        label-class-name="formLabel"
        content-class-name="formContent"
      >
        <el-descriptions-item :label="`${detail_title}類別`">
          {{ $store.getters.getUserAccount.org_classification }}
        </el-descriptions-item>
        <el-descriptions-item :label="`${detail_title}地址`">
          {{ $store.getters.getUserAccount.org_address }}
        </el-descriptions-item>
        <el-descriptions-item :label="`${detail_title}抬頭`">
          {{ $store.getters.getUserAccount.org_name }}
        </el-descriptions-item>
        <el-descriptions-item :label="`${detail_title}電話`">
          {{ $store.getters.getUserAccount.org_phone }}
        </el-descriptions-item>
        <el-descriptions-item label="統一編號">
          {{ $store.getters.getUserAccount.org_tax }}
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
let typeName = ['個人', '企業', '學術單位/公益團體'];
export default {
  name: 'user-data',
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getUserAccount']),
    user_type_name() {
      return typeName[this.getUserAccount.type_code * 1 - 1];
    },
    detail_title() {
      return this.getUserAccount.type_code === 2 ? '企業' : '單位';
    },
  },
};
</script>
