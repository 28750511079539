<template>
  <el-card class="box-card profile-card">
    <el-button
      style="float: right; padding: 3px 0"
      type="text"
      @click="confirm('getInitInvoice')"
    >
      儲存
    </el-button>
    <el-button
      style="float: right; padding: 3px 0; margin-right: 8px"
      type="text"
      @click="cancel('getInitInvoice')"
    >
      取消
    </el-button>
    <!-- 編輯發票 -->
    <el-descriptions
      title="發票設定"
      :colon="false"
      :column="2"
      label-class-name="formLabel"
      content-class-name="formContent"
    >
      <!-- 格式三選一 -->
      <el-descriptions-item label="發票格式">
        <el-radio-group v-model="getInitInvoice.invoice_type">
          <el-radio
            v-for="item in invoiceData"
            :key="item.value"
            :label="item.value"
            @change="clearErr('getInitInvoice')"
          >
            {{ item.name }}
          </el-radio>
        </el-radio-group>
      </el-descriptions-item>
      <!-- 格式對應表單 -->
      <el-descriptions-item label-class-name="fix-width">
        <el-form
          :model="getInitInvoice"
          status-icon
          :rules="rules"
          ref="getInitInvoice"
          label-width="60px"
          :hide-required-asterisk="true"
        >
          <div
            id="invoice-type1"
            v-show="getInitInvoice.invoice_type === 'type1'"
          >
            <el-form-item label="抬頭" prop="org_name">
              <el-input
                type="text"
                v-model="getInitInvoice.org_name"
              ></el-input>
            </el-form-item>
            <el-form-item label="統一編號" prop="org_tax">
              <el-input type="text" v-model="getInitInvoice.org_tax"></el-input>
            </el-form-item>
          </div>

          <div
            id="invoice-type2"
            v-show="getInitInvoice.invoice_type === 'type2'"
          >
            <el-form-item label="儲存形式" prop="invoice_code">
              <el-radio-group
                v-model="getInitInvoice.invoice_code_type"
                @change="switchInput('getInitInvoice')"
              >
                <el-radio :label="'手機條碼載具'">手機條碼載具</el-radio>
                <el-radio :label="'自然人憑證載具'">自然人憑證載具</el-radio>
                <el-input
                  v-model="getInitInvoice.invoice_code"
                  :placeholder="code_placeholder"
                  style="width: 250px"
                ></el-input>
              </el-radio-group>
            </el-form-item>
          </div>

          <div
            id="invoice-type3"
            v-show="getInitInvoice.invoice_type === 'type3'"
          >
            <el-form-item label="捐贈碼" prop="donate">
              <p v-if="!getInitInvoice.donate" class="donate">尚未選擇</p>
              <p class="donate">{{ getInitInvoice.donate }}</p>
              <img
                class="select-btn"
                src="../../assets/image/icon-plus.png"
                @click="openLoveCode"
              />
            </el-form-item>
          </div>
        </el-form>
      </el-descriptions-item>
    </el-descriptions>
    <!-- 捐贈碼彈跳視窗 -->
    <el-dialog title="選擇捐贈碼" :visible.sync="dialogDonateVisible">
      <el-table
        ref="loveCodeTable"
        :data="loveCodeData"
        highlight-current-row
        @current-change="handleCurrentChange"
        height="400"
      >
        <el-table-column
          property="love_code"
          label="捐贈碼"
          width="150"
        ></el-table-column>
        <el-table-column
          property="social_welfare_name"
          label="捐贈單位"
        ></el-table-column>
      </el-table>
    </el-dialog>
  </el-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'edit-invoice',
  props: ['action'],
  data() {
    //解決v-show='false'仍然會驗證的問題：加入invoice_type的判斷條件

    //檢查抬頭
    let validateName = (rule, value, callback) => {
      if (this.getInitInvoice.invoice_type === 'type1') {
        if (!value) {
          callback(new Error('請輸入抬頭'));
        } else {
          if (value.length <= 5) {
            callback(new Error('請輸入正確的抬頭資訊'));
          } else {
            callback();
          }
        }
      } else {
        callback();
      }
    };

    //檢查統編
    let validateTax = (rule, value, callback) => {
      if (this.getInitInvoice.invoice_type === 'type1') {
        if (!value) {
          callback(new Error('請輸入統一編號'));
        } else {
          const regex = /^\d{8}$/;

          if (!regex.test(value)) {
            callback(new Error('請輸入正確的編號格式'));
          } else {
            callback();
          }
        }
      } else {
        callback();
      }
    };

    //檢查載具碼
    let validateCode = (rule, value, callback) => {
      if (this.getInitInvoice.invoice_type === 'type2') {
        if (!value) {
          callback(new Error('請輸入載具號碼'));
        } else {
          // 檢查手機條碼
          if (this.getInitInvoice.invoice_code_type === '手機條碼載具') {
            const regex = /^\/[A-Z0-9+-.]{7}$/;

            if (!regex.test(value)) {
              callback(new Error('錯誤輸入:/+[大寫英數字+-.]7碼，共8碼'));
            } else {
              callback();
            }
          } else {
            //檢查自然人憑證
            const regex = /^[A-Z]{2}[\d]{14}$/;

            if (!regex.test(value)) {
              callback(new Error('錯誤輸入:[大寫英文]2碼+[數字]14碼，共16碼'));
            } else {
              callback();
            }
          }
        }
      } else {
        callback();
      }
    };

    //檢查捐贈碼
    let validateDonate = (rule, value, callback) => {
      if (this.getInitInvoice.invoice_type === 'type3') {
        if (!value) {
          callback(new Error('請選擇捐贈碼'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };

    return {
      invoiceData: [
        {
          name: '三聯式電子發票(公司)',
          value: 'type1',
        },
        {
          name: '二聯式電子發票(個人)',
          value: 'type2',
        },
        {
          name: '捐贈',
          value: 'type3',
        },
      ],
      rules: {
        org_name: [{ validator: validateName, trigger: 'change' }],
        org_tax: [{ validator: validateTax, trigger: 'change' }],
        invoice_code: [{ validator: validateCode, trigger: 'blur' }],
        donate: [{ validator: validateDonate, trigger: 'change' }],
      },
      dialogDonateVisible: false,
      loveCodeData: [],
    };
  },
  computed: {
    ...mapGetters(['getInitInvoice']),
    code_placeholder() {
      return this.getInitInvoice.invoice_code_type === '手機條碼載具'
        ? '請輸入手機條碼載具，共8碼'
        : '請輸入自然人憑證載具，共16碼';
    },
  },
  methods: {
    ...mapActions(['doEditInvoice', 'doGetLoveCode']),
    async confirm(formName) {
      try {
        const valid = await this.$refs[formName].validate().catch((e) => e);
        if (!valid) return false;

        let invoice_heading;
        let carrier_type;
        let carrier_number;
        let social_welfare_name;

        switch (this.getInitInvoice.invoice_type) {
          case 'type1':
            carrier_type = 0;
            carrier_number = this.getInitInvoice.org_tax;
            invoice_heading = this.getInitInvoice.org_name;
            break;
          case 'type2':
            if (this.getInitInvoice.invoice_code_type === '手機條碼載具') {
              carrier_type = 1;
              carrier_number = this.getInitInvoice.invoice_code;
            } else {
              carrier_type = 2;
              carrier_number = this.getInitInvoice.invoice_code;
            }
            break;
          case 'type3':
            carrier_type = 3;
            carrier_number = this.getInitInvoice.love_code;
            social_welfare_name = this.getInitInvoice.social_welfare_name;
            break;
        }
        let setData = {
          user_id: sessionStorage.getItem('user_id'),
          carrier_type,
          carrier_number,
          invoice_heading,
          social_welfare_name,
        };
        this.doEditInvoice(setData).then(() => {
          this.$emit('update:action', 'get');
        });
      } catch {
        (e) => e;
      }
    },
    async openLoveCode() {
      this.dialogDonateVisible = true;
      let queryLove = {
        key_word: '',
        start: 0,
        length: 50,
      };
      const res = await this.doGetLoveCode(queryLove);
      // console.log(res);
      res.data.forEach((el) => {
        this.loveCodeData.push({
          love_code: el.love_code,
          social_welfare_name: el.social_welfare_name,
        });
      });
    },
    handleCurrentChange(val) {
      let love_code = val.love_code;
      let social_welfare_name = val.social_welfare_name;
      this.getInitInvoice.donate = love_code + ' ' + social_welfare_name;
      this.getInitInvoice.love_code = love_code;
      this.getInitInvoice.social_welfare_name = social_welfare_name;
      this.dialogDonateVisible = false;
    },
    cancel(formName) {
      this.$emit('update:action', 'get');
      this.$refs[formName].clearValidate();
    },
    clearErr(formName) {
      this.$refs[formName].clearValidate();
    },
    switchInput(formName) {
      this.$refs[formName].clearValidate();
      this.getInitInvoice.invoice_code = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.el-radio-group {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}

.el-radio-group label {
  margin-bottom: 10px;
  line-height: 1.5;
}

.fix-width {
  width: 0;
}

/deep/ .el-descriptions-item {
  height: 117px;
  vertical-align: top;
}

/deep/ .el-form-item__label {
  color: #909399;
  padding: 0;
  line-height: 30px;
}
/deep/ .el-form-item__content {
  margin-left: 90px !important;
  line-height: 30px;
}
/deep/ .el-input__inner {
  height: 30px;
  line-height: 30px;
}
/deep/ .el-input__icon {
  line-height: 30px;
}

.donate {
  width: 100%;
  min-width: 100px;
  max-width: 250px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.select-btn {
  width: 30px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    filter: opacity(0.7);
  }
}
</style>
